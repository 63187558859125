<template>
  <section class="settings">
    <div class="settings__programmer" v-if="showPendingSettings">
      <p class="settings__programmer--title">Cronograma de encuestas</p>
      <div class="settings__programmer--timer">
        <div class="settings__programmer--seccion">
          <div class="settings__programmer--calendar">
            <iconic name="calendar"></iconic>
            <p>Fecha inicial</p>
          </div>
          <div>
            <input type="date" name="startDate" v-model="dateInit" class="settings__programmer--date" />
          </div>
        </div>
        <div class="settings__programmer--seccion">
          <div class="settings__programmer--clock">
            <iconic name="clock"></iconic>
            <p>Hora inicio</p>
          </div>
          <div>
            <select v-model="selectedHourInit" @click="showHours" class="settings__programmer--time">
              <option value="">Selecionar hora</option>
              <option v-for="hour in hours" :key="hour">{{ hour }}</option>
            </select>
          </div>
        </div>
        <div class="settings__programmer--seccion">
          <div class="settings__programmer--calendar">
            <iconic name="calendar"></iconic>
            <p>Fecha final</p>
          </div>
          <div>
            <input type="date" name="dateEnd" v-model="dateEnd" class="settings__programmer--date" />
          </div>
        </div>
        <div class="settings__programmer--seccion">
          <div class="settings__programmer--clock">
            <iconic name="clock"></iconic>
            <p>Hora final</p>
          </div>
          <div>
            <select v-model="selectedHour" @click="showHours" class="settings__programmer--time">
              <option value="">Seleccionar hora</option>
              <option v-for="hour in hours" :key="hour">{{ hour }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="settings__politics">
      <p class="settings__politics--title">Politicas</p>
      <div class="settings__politics--item" v-if="showPendingSettings">
        <p class="settings__politics--text">Publicar inmediatamente los votos de las personas</p>
        <Toggle class="settings__politics--toggle" />
      </div>
      <div class="settings__politics--item">
        <p class="settings__politics--text">Publicar inmediatamente el numero de votos</p>
        <Toggle class="settings__politics--toggle" @isChecked="isChecked" :checked="showResults" />
      </div>
    </div>
    <div class="settings__permissions">
      <p class="settings__permissions--title">Permisos</p>
      <div class="settings__permissions--cities">
        <p class="settings__permissions--names">Ciudades</p>
        <div class="settings__permissions--group">
          <div v-for="(city, index) in cities" :key="index" class="settings__permissions--check">
            <input
              type="checkbox"
              :id="`cities-${index}`"
              :name="`cities-${city.name}`"
              :value="city.name"
              :checked="isItemChecked(getPropertySafely('cities'), city._id)"
              @change="check($event, 'cities', city._id)"
              @click="checkHeadquartersCities($event, city._id)"
            />
            <label :for="`cities-${index}`">{{ city.name }}</label>
          </div>
        </div>
      </div>
      <div class="settings__permissions--offices">
        <p class="settings__permissions--names">Sedes</p>
        <div class="settings__permissions--group">
          <div v-for="(office, index) in officesData" :key="index" class="settings__permissions--check">
            <input
              type="checkbox"
              :id="`office-${index}`"
              :name="`office-${office.officeName}`"
              :value="office.officeName"
              :checked="isItemChecked(getPropertySafely('offices'), office._id)"
              @change="check($event, 'offices', office._id)"
              @click="checkHeadquartersOffices($event, office._id, office.cityName, office.cityId)"
            />
            <label :for="`office-${index}`">{{ office.officeName }}</label>
          </div>
        </div>
      </div>
      <div class="settings__permissions--users">
        <p class="settings__permissions--names">Usuarios</p>
        <div class="settings__permissions--group">
          <div v-for="(role, index) in roles" :key="index" class="settings__permissions--check">
            <input type="checkbox" :id="`users-${index}`" :name="`users-${index}`" :value="index" :checked="policiesView.roles.includes(index)" @change="check($event, 'roles', role)" />
            <label :for="`users-${index}`" capitalize>{{ role }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="create__btns">
      <button type="button" class="create__btns--cancel" @click="backViewPolls">Cancelar</button>
      <button type="button" class="create__btns--save" @click="savePolicesPolls">Guardar</button>
    </div>
  </section>
</template>
<script>
  import axios from "@/api/axios";
  export default {
    name: "settings",
    components: {
      Toggle: () => import("@/components/input/ToggleInput.vue"),
    },
    props: ["dataId", "poll"],
    data() {
      return {
        selectedHourInit: "",
        selectedHour: "",
        dateInit: "",
        dateEnd: "",
        hours: [],
        hoursShowed: "",
        cities: [],
        officesData: [],
        roles: this.$global.dictionary.roles_es_auto,
        showResults: false,
        showPendingSettings: false,
        rolesDic: { empleado: "employee", superadmin: "superadmin", coordinador: "coordinator", monitor: "monitor", modelo: "model" },
        policiesView: {
          cities: [],
          offices: [],
          areas: [],
          users: [],
          roles: [],
        },
      };
    },
    methods: {
      isItemChecked(items, value) {
        return items?.some((item) => item === value);
      },
      getPropertySafely(obj) {
        return this.policiesView[obj];
      },
      updatePoliciesList(policiesList, itemId, shouldAdd) {
        const index = policiesList.findIndex((item) => item === itemId);
        if (shouldAdd && index === -1) {
          policiesList.push(itemId);
        } else if (!shouldAdd && index > -1) {
          policiesList.splice(index, 1);
        }
      },
      syncOfficeCheckboxes(cityName, isChecked) {
        this.officesData.forEach((office) => {
          if (office.cityName.localeCompare(cityName, "es", { sensitivity: "base" }) === 0) {
            const officeInput = document.querySelector(`input[name="office-${office.officeName}"]`);
            if (officeInput) {
              officeInput.checked = isChecked;
              this.updatePoliciesList(this.policiesView.offices, office._id, isChecked);
            }
          }
        });
      },
      checkHeadquartersCities(event, cityId) {
        const isChecked = event.target.checked;
        this.syncOfficeCheckboxes(event.target.value, isChecked);
        this.updatePoliciesList(this.policiesView.cities, cityId, isChecked);
      },
      checkHeadquartersOffices(event, officeId, cityName, cityId) {
        const isSelected = event.target.checked;

        this.updatePoliciesList(this.policiesView.offices, officeId, isSelected);
        if (cityId) {
          const officesInCity = this.officesData.filter((office) => office.cityId === cityId);

          const anyOfficeSelected = officesInCity.some((office) => document.querySelector(`input[name="office-${office.officeName}"]`)?.checked);

          const cityInput = document.querySelector(`input[name="cities-${cityName}"]`);
          if (cityInput) {
            cityInput.checked = anyOfficeSelected;
            this.updatePoliciesList(this.policiesView.cities, cityId, anyOfficeSelected);
          }
        }
      },
      check(event, tag, id) {
        const isChecked = event.srcElement.checked ? true : false;
        const setValue = tag === "roles" ? this.rolesDic[id] : id;
        if (isChecked) {
          !this.policiesView[tag].includes(setValue) && this.policiesView[tag].push(setValue);
        } else {
          let index = this.policiesView[tag].indexOf(setValue);
          if (index !== -1) {
            this.policiesView[tag].splice(index, 1);
          }
        }
      },
      isChecked(value) {
        this.showResults = value;
      },
      async savePolicesPolls() {
        const resp = await this.$store.dispatch("polls/sendPolicies", {
          id: this.dataId,
          startDate: this.dateInit,
          showResults: this.showResults,
          endDate: this.dateEnd,
          policiesView: this.policiesView,
        });
        if (resp.success) {
          this.$alerts.dataSendSuccess({ message: "Encuesta actualizada" });
        } else {
          this.$alerts.dataSendError({ message: "Error" });
        }
      },
      backViewPolls() {
        this.$router.push({ name: "polls" });
      },
      async fetchCities() {
        try {
          const { data = [] } = await axios.get("/cities/");
          this.cities = data.map((element) => ({ name: element.cityName, _id: element._id }));
        } catch (error) {
          console.error("Error al obtener oficinas:", error);
        }
      },
      async fetchOffices() {
        try {
          const { data = [] } = await axios.get("/offices/");
          this.officesData = data;
        } catch (error) {
          console.error("Error al obtener oficinas:", error);
        }
      },
      pollExist() {
        if (this.poll) {
          const { startDate, endDate, showResults, policiesView } = this.poll;
          this.dateInit = startDate && new Date(startDate).toISOString().split("T")[0];
          this.dateEnd = endDate && new Date(endDate).toISOString().split("T")[0];
          this.showResults = showResults;
          this.policiesView = policiesView;
        }
      },
      showHours() {
        this.hours = [];
        this.hoursShowed = "";
        let currentTime = "00:00";

        for (let i = 0; i < 24; i++) {
          if (this.selectedHour && currentTime > this.selectedHour) {
            break;
          }
          this.hours.push(currentTime);

          const [hh, mm] = currentTime.split(":");
          let minutes = parseInt(hh) * 60 + parseInt(mm);
          minutes += 60;
          const newTime = new Date(0, 0, 0, Math.floor(minutes / 60), minutes % 60);
          currentTime = newTime.toTimeString().substring(0, 5);
        }

        this.hoursShowed = "Horas mostradas: " + this.hours.join(", ");
      },
    },
    watch: {
      poll: {
        immediate: true,
        handler(newVal) {
          if (newVal) {
            this.pollExist();
          }
        },
      },
    },

    mounted() {
      this.fetchCities();
      this.fetchOffices();
    },
  };
</script>
<style lang="scss">
  .settings {
    &__programmer {
      width: 100%;
      max-width: 1440px;
      height: 100%;
      margin: 1% 0;
      border-radius: 6px;
      background-color: #fff;
      padding: 15px 20px 20px 20px;
      border: 1px solid #00000040;
      box-shadow: 0px 1px 4px 0px #00000040;
      &--title {
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        line-height: 20px;
        letter-spacing: 0em;
        font-family: $primary-font;
      }
      &--timer {
        gap: 2%;
        display: flex;
        margin-top: 15px;
      }
      &--calendar,
      &--clock {
        display: flex;
        gap: 10px;
        margin-bottom: 10px;
      }
      &--date {
        border: 1px solid #00000040;
        border-radius: 6px;
        height: 35px;
        width: 220px;
        padding: 0 10px;
      }
      &--time {
        border: 1px solid #00000040;
        border-radius: 6px;
        height: 35px;
        width: 220px;
        padding: 0 10px;
      }
    }
    &__politics {
      width: 100%;
      max-width: 1440px;
      height: 100%;
      margin: 1% 0;
      border-radius: 6px;
      background-color: #fff;
      padding: 15px 20px 20px 20px;
      border: 1px solid #00000040;
      box-shadow: 0px 1px 4px 0px #00000040;
      &--title {
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        line-height: 20px;
        letter-spacing: 0em;
        font-family: $primary-font;
      }
      &--item {
        display: flex;
        margin: 10px 0;
        padding: 10px 0;
        justify-content: space-between;
        border-bottom: 1px solid #00000040;
      }
    }
    &__permissions {
      width: 100%;
      max-width: 1440px;
      height: 100%;
      margin: 1% 0;
      border-radius: 6px;
      background-color: #fff;
      padding: 15px 20px 20px 20px;
      border: 1px solid #00000040;
      box-shadow: 0px 1px 4px 0px #00000040;
      &--title {
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        line-height: 20px;
        letter-spacing: 0em;
        font-family: $primary-font;
      }
      &--cities {
        padding: 10px 0;
      }
      &--users {
        padding: 10px 0;
      }
      &--names {
        padding: 5px 0;
      }
      &--group {
        gap: 10px;
        display: flex;
        padding: 5px 0;
        flex-wrap: wrap;
        border-bottom: 1px solid #00000040;
      }
      &--check {
        display: flex;
        gap: 5px;
        margin-right: 15px;
      }
    }
  }
</style>
